<template>
  <div>
  <v-sheet dark color="secondary lighten-1" class="d-flex justify-center">
    <v-avatar rounded
        style="transform:translateY(1.5em)" size="130">
      <v-img
          class=""
          style="position: relative"
          id="profile-img"
          height="100%"
          width="100%"
          :src="currentUser.profilePic?'data:image/png;base64,'+currentUser.profilePic.file :'//ssl.gstatic.com/accounts/ui/avatar_2x.png'"
      >
        <v-sheet style="position: absolute;left:0;bottom:0;opacity: 0.7" color="secondary lighten-1" class="text-center" height="20px" width="100%">Edit</v-sheet>


      </v-img>


      <input type="file"
             class="file-input"
             style="opacity:0;width:100%;height:100%;position: absolute;top:0;left:0"
             @change="setImage"
             accept="image/png, image/jpeg,image/bmp"
      />
    </v-avatar>
  </v-sheet>
      <v-sheet class="pa-xs-0 pt-10 pa-md-7">
        <v-expansion-panels>
          <ExpansionPanelSimple header="" open-headline="Name">
            <template v-slot:panel-content>
              <v-text-field outlined dense @change="updateUser" v-model="currentUser.firstName" class="mr-5" label="First Name" ></v-text-field>
              <v-text-field outlined dense @change="updateUser" v-model="currentUser.lastName" class="mr-5" label="Last Name" ></v-text-field>



            </template>
            <template v-slot:header-content>
              <div class="d-flex flex-wrap">
              <span class="caption" style="width:100%">Name</span>
              <span class="mr-1" v-html="currentUser.firstName"></span>
              <span v-html="currentUser.lastName"></span>
              </div>
            </template>
            <template v-slot:icon>
              <v-icon>mdi-account</v-icon>
            </template>
          </ExpansionPanelSimple>
          <ExpansionPanelSimple open-headline="Email">
            <template v-slot:panel-content>
              <v-text-field v-model="currentUser.email" disabled outlined dense class="mr-5" label="E-mail" ></v-text-field>

            </template>
            <template v-slot:header-content>
              <div class="d-flex flex-wrap">
                <span style="width:100%">Email</span>
              <span v-html="currentUser.email"></span>
              </div>
            </template>
            <template v-slot:icon>
              <v-icon>mdi-email</v-icon>
            </template>
          </ExpansionPanelSimple>

          <ExpansionPanelSimple open-headline="Zusätzliche Informationen">
            <template v-slot:panel-content>
              <v-textarea counter rows="5" auto-grow v-model="currentUser.bio"  @change="updateUser" outlined dense class="mr-5" label="Biographical Information" ></v-textarea>

            </template>
            <template v-slot:header-content>
              <div class="d-flex flex-wrap">
                <span style="width:100%">Zusätzliche Informationen</span>
              <span v-if="currentUser.bio">
                <span  v-html="currentUser.bio.substring(0,20)"></span><span v-if="currentUser.bio.length>20">...</span>
              </span>
              </div>
            </template>
            <template v-slot:icon>
              <v-icon>mdi-text</v-icon>
            </template>
          </ExpansionPanelSimple>

        </v-expansion-panels>

        <v-dialog content-class="overflow-hidden" fullscreen max-width="100%" style="max-height: 100%;" v-model="hasImage">
          <v-card>
            <v-card-actions>
              <v-btn text @click="hasImage=false">Cancel</v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="crop"  :loading="loading">Upload</v-btn>
            </v-card-actions>

            <cropper
                ref="cropper"
                class="upload-example-cropper"
                :src="img"
                style="width:100%; height:100%"
                :stencil-props="{
		handlers: {},
		movable: false,
		scalable: false,
	}"
                :stencil-size="{
		width: 280,
		height: 280
	}"
                image-restriction="stencil"
                :stencil-component="$options.components.Stencil"


            />

          </v-card>
        </v-dialog>

    </v-sheet>
  </div>
</template>

<script>
import Compressor from 'compressorjs';
    import ApiService from "../services/api.service"
    import UploadFile from "../services/upload-file"
    import ExpansionPanelSimple from "@/views/ExpansionPanel/ExpansionPanelSimple";
    import Stencil from "./Stencil";
    import Notification from "@/models/notification";

    export default {
        name: "Profile",
      // eslint-disable-next-line vue/no-unused-components
      components: {ExpansionPanelSimple,Stencil},
      created(){

        },
        data(){
          return{
            loading:false,
            img:null,
            hasImage:false,
            tags:{
              input:"",
              all:[],
              selected:[],
              selectedObjects:[]
            },
              message:"",
              user:null,
              bread:[
                  {
                      text: 'Home',
                      disabled: false,
                      href: '../',
                  },
                  {
                      text: 'Profile',
                      disabled: true,
                      href: '',
                  },
              ],
          }
        },
        computed: {
            currentUser() {
                return this.$store.state.auth.user;
            }
        },
        mounted() {
            if (!this.currentUser) {
                this.$router.push('/login');
            }
        },
        methods:{
          setImage: function (e) {
            this.hasImage = true
            let files = e.target.files || e.dataTransfer.files;
            if(!files.length) return;
            else this.img = URL.createObjectURL(files[0]);

          },

          updateUser(){
            let self=this;
            ApiService.post("user/update",this.currentUser).then(response => {
              let user =JSON.parse(localStorage.getItem("user"));
              let roles = user.roles;
              let merged = {...user, ...response.data};
              merged.roles=roles;
              this.$store.dispatch("auth/updateLocalUser",merged);
              self.$store.dispatch("loading/success");
            });
          },
          crop() {
            this.loading=true;
            const result = this.$refs.cropper.getResult();
            this.compressImage(this.dataURItoBlob(result.canvas.toDataURL()));

          },

          dataURItoBlob(dataURI) {
// convert base64/URLEncoded data component to raw binary data held in a string
            var byteString;
            if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
            else
              byteString = unescape(dataURI.split(',')[1]);
// separate out the mime component
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
// write the bytes of the string to a typed array
            var ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], {type:mimeString});
          },
          compressImage(file){
            let self=this;
            new Compressor(file, {
              quality: 0.4,
              width:720,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                self.changeImage(result)
              },
              error(err) {
                console.log(err.message);
                self.setSnack({
                  message: "Fehler beim Komprimieren Ihrer Datei. Bitte verwenden Sie ein anderes Bild",
                  color: "error"
                })
              },
            });
          },
          changeImage(image){
            let self=this;

            if(image.size>1000000){
              self.$store.dispatch("notificationsStore/add",new Notification("error","Datei zu groß "+Math.round(image.size/1000000)+" /1MB. Versuchen Sie das Bild zu verkleinern",false));
              self.loading=false;
            }
            else {
              UploadFile(image).then(response => {
                self.currentUser.profilePic=response.data;

                self.updateUser();
                self.loading=false;
                self.hasImage=false;
              })
            }

          },
            handleLogout:function(){
                this.$store.dispatch('auth/logout')
                this.$router.push("/")
            },
            sendConfirmationAgain(){
                let self=this;
                ApiService.post("token/send",self.currentUser).then(function(){

                    self.message="Erfolgreich gesendet"

                }).catch(function(error){
                    self.message=error
                });

            }
        }
    }
</script>

<style scoped>
</style>
