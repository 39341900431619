<template>
  <v-expansion-panel  class="noshadowexpansionpanel elevation-0">
    <v-expansion-panel-header v-slot="{ open }">
      <v-row no-gutters class="align-center">
        <v-col cols="1">
          <slot name="icon">

          </slot>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
            cols="9"
            class="text--secondary"
        >
          <v-fade-transition leave-absolute>
            <span v-if="open" v-html="openHeadline"></span>
            <v-row
                v-else
                no-gutters
                style="width: 100%"
            >
              <slot name="header-content">

              </slot>
            </v-row>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot name="panel-content"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "ExpansionPanelSimple",
  data(){
    return{
    }
  },
  props:{
    openHeadline:String,
    header:String
  }
}
</script>

<style scoped>
.noshadowexpansionpanel:before{
  box-shadow: none;
}
</style>